<template>
  <div class="root" v-loading="loadingPrice">
    <header-space/>
    <div class="wrap">
      <!--<breadcrumb :arrList='arrList'></breadcrumb>-->

      <!--地址-->
      <el-card class="box-card" shadow="never">
        <div slot="header">{{ $t('newadd.t.默认地址')}}：</div>
        <el-row>
          <address-com @change="post(true)"  :address="address"  v-if="address.id"/>
        </el-row>
      </el-card>

      <!--商品信息-->
      <el-card class="box-card" shadow="never">
        <div slot="header">{{ $t("goodsPay.BrandName") }}</div>
        <div>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" :label="$t('goodsPay.goodsType')" width="180">
              <template v-slot="scope">
                {{ $t("headerMenu.customPlatform." + (Number(type) + 1)) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('goodsPay.goodsPrice')">
              <template v-slot="scope">
                ￥{{ scope.row.price }}
              </template>
            </el-table-column>
            <el-table-column prop="size" label="数量" :label="$t('goodsPay.goodsNum')"/>
            <el-table-column :label="$t('goodsPay.goodsSubtotal')">
              <template v-slot="scope">
                ￥{{ scope.row.total }}
              </template>
            </el-table-column>
          </el-table>

        </div>
        <el-row style="padding: 20px 10px;">
          <div style="color: #5C5E62;font-weight: bold;margin-bottom: 20px;">{{ $t('newadd.t.保险赔付')}}</div>
          <el-row type="flex" justify="space-between">
            <el-col :span="23">
              <i class="el-icon-truck"></i>
              <span style=";margin-left: 20px;"> {{ $t('newadd.t.运险费')}}：</span>
              <span style="color: red;font-size: 25px">{{ price.insureMoney }}</span>
              <span>元</span>
            </el-col>
            <el-col :span="1">
              <el-switch v-model="isepx"></el-switch>
            </el-col>
          </el-row>
        </el-row>
      </el-card>

      <!--表单-->
      <el-card class="box-card" shadow="never">
        <el-form inline>


          <el-form-item :label="$t('goodsDetail.Payment')  ">
            <el-select v-model="payType" size="small" @change="post(true)">
              <el-option :value="1" :label=" $t('newadd.t.全款支付')"></el-option>
              <el-option :value="2" :label=" $t('newadd.t.分期支付')"></el-option>
            </el-select>
          </el-form-item>

          

          <el-form-item label="图号">
            <el-input v-model="userFigureNumber" size="small"></el-input>
          </el-form-item>

        </el-form>
      </el-card>

      <el-card class="box-card" shadow="never">

        <!--开票-->
        <el-row type="flex" align="middle" slot="header">
          <el-col :span="3">
            <el-switch active-text="我要开票" v-model="isinvoice"></el-switch>
          </el-col>
          <el-col :span="1" v-if="isinvoice">
            <i class="el-icon-edit-outline hover" style="color: #3A8EE6;" @click="goInvoice"></i>
          </el-col>
        </el-row>

        <!--备注-->
        <el-row style="margin-top: 20px;">
          <el-form class="demo-ruleForm" label-width="100px" label-position="left">
            <el-form-item label="订单备注">
              <el-input placeholder="订单备注" v-model="textarea" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </el-row>

        <!--费用-->
        <el-row style="margin-top: 20px;">
          <el-col :span="12" style="font-weight: bold;">
            <el-row type="flex">
              <el-col :span="3">总计:</el-col>
              <el-col>数量总计:{{ tableData.length }}件</el-col>
            </el-row>
          </el-col>

          <el-col :span="12" style="text-align: right;line-height: 30px;color: #666;">
            <div>
              <span>产品总金额：</span><span style="font-weight: bold;"> {{ price.goodsMoney }} </span> <span>元</span>
            </div>
            <div>
              <span>运费总计：</span><span style="font-weight: bold;">{{ price.freightMoney }}</span> <span>元 </span>
            </div>
            <div>
              <span>运险费：</span><span style="font-weight: bold;">{{ isepx ? price.insureMoney : 0 }}</span>
              <span>元 </span>
            </div>
            <div>
              <span>优惠金额：</span><span style="font-weight: bold;">{{ discount }}</span>
              <span>元 </span>
            </div>
          </el-col>
        </el-row>

        <el-row class="footer">
          <el-row type="flex" justify="end" style="margin-top: 20px;">
            <el-checkbox v-model="iselse">他人代付</el-checkbox>
          </el-row>

          <div class="content">
            <div class="money">
              <span> 应付总金额：</span>
              <!--减去运费-->
              <span class="light">{{
                  $bigDecimal.subtract(price.money, isepx ? price.insureMoney : 0)
                }}</span>
              <span> 元 </span>
            </div>
            <div class="btn" @click="post()">提交订单</div>
          </div>
        </el-row>

        <!--<el-row type="flex" justify="end">-->
        <!--  <el-button type="primary" size="medium">提交订单</el-button>-->
        <!--</el-row>-->


      </el-card>

    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/breadcrumb';

import {common} from "@/api/common";
import {addProxy} from "@/api/order";
import {mapGetters} from "vuex";
import HeaderSpace from "@/components/HeaderSpace";
import Address from "@/components/Address";
import AddressCom from "@/components/Address";
import {getGoodsCustomMode} from "@/utils";
import FreightCalculate from "@/views/Custom/components/calculate/freight/freight";
import {
		getToken,
		save,
		get,changemodile
	} from "@/utils/auth";

export default {
  components: {AddressCom, Address, HeaderSpace, breadcrumb},
  computed: {
    ...mapGetters(["fileServer", "customInfo", "config"]),
  },
  data() {
    return {
      type: this.$route.query.type,
      module: null,

      loadingPrice: false,
      discount: 0,

      tableData: [],
      expmoeny: "",
      // 运费险
      isepx: false,
      // 开票
      isinvoice: false,
      textarea: "",
      // 代付
      iselse: false,

      freightInsurance: 0,
      goodsId: [],
      numId: [],
      payType: 1,
      fromAddress: 0,
      userFigureNumber:'',

address:'',
      price: {goodsMoney: 0, freightMoney: 0, insureMoney: 0, money: 0},
    }
  },
  mounted() {
    if (!this.customInfo.confirm) {
      this.$message.warning("设计数据不存在，请重新设计。")
      this.$router.back();
      return;
    }
    this.module = getGoodsCustomMode(this.type)

    let {buyModel} = this.customInfo;
    let {size} = buyModel
    let {price, priceTwo} = buyModel.price;
    price = buyModel.type ? priceTwo : price;
    let goodsModel = {
      price, size,
      total: this.$bigDecimal.multiply(price, size)
    }
    this.tableData.push(goodsModel);

    this.freightInsurance = goodsModel.price * goodsModel.size * this.config.orderInsureRatio

    this.post(true)
  },
  methods: {
    goInvoice() {
      this.$router.push("/sentInvoice")
    },
    // 提交
    async post(onlyCalc) {
      let address = await this.$store.dispatch("GetUserAddress")
	  
      let calc = FreightCalculate.from(this.customInfo.buyModel)
          .setFrom(this.fromAddress)
          .setLine(this.customInfo.supplierName)
          .setTo(address.code)
          .get()

      let data = {
        addressId: address.id,
        calcFreightParam: calc,
        calcUserBoxParam: this.customInfo.boxCalcModel,
        calcUserParam: this.customInfo.calcModel,
        calcUserPriceParam: this.customInfo.buyModel.priceCalc,
        goodsParam: this.customInfo,
        insure: onlyCalc || this.isepx,
        model: this.module,
        num: this.customInfo.buyModel.size,
        payType: this.payType,
        remark: this.textarea,
        requireInvoice: true,
        supplierId: this.customInfo.supplierId,
        userFigureNumber: this.userFigureNumber,
        onlyCalc: !!onlyCalc,
      }

      await this.doPost(data)
	  
      if (address.phone != null) {
        let datas = changemodile(address.phone);
        address.phone = datas.phone;
        address.countryvalue = datas.code;  
      }else {
        address.phone = "";
        address.countryvalue = "";
      }
  	  this.address = address;
    },
    async doPost(data) {
      this.loadingPrice = true;
      let url = this.type ? "addMpfUser" : "addMstUser"
      try {
        let res = await this.$req.post("/client/order/" + url, data)
        console.log(res)
        // 只计算
        if (data.onlyCalc) {
          let {
            goodsMoney, freightMoney, insureMoney, money,
            discountAgain, discountContact, discountFull
          } = res;

          this.price = {goodsMoney, freightMoney, insureMoney, money}
          this.discount = this.$bigDecimal.add(discountAgain, this.$bigDecimal.add(discountContact, discountFull))
        }
        // 提交
        else {
         this.$message({
         	message: '下单成功',
         	type: 'success',
         	onClose: (res) => {
         		this.$router.push({
         			path: "/orderadmin"
         		})
         	}
         });
          let {id} = res

        }
      } finally {
        this.loadingPrice = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.root {
  background: #f1f1f1;
  padding-bottom: 60px;

  .wrap {
    width: 1000px;
    margin: 0 auto;
    color: #555;
    font-size: 14px;
  }

  .box-card {
    margin-top: 20px;
  }

  .el-icon-truck {
    font-size: 30px;
  }
}

.footer {
  border-top: 1px solid #eee;

  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translate(20px, 20px);

    .money {
      font-size: 18px;
      color: #5C5E62;

      .light {
        font-weight: bold;
        color: #FF3333;
        font-size: 24px;
        margin: 0 12px;
      }
    }

    .btn {
      height: 60px;
      line-height: 60px;
      width: 230px;
      background-color: #3C66D7;
      color: white;
      text-align: center;
      font-size: 24px;
      cursor: pointer;
      margin-left: 30px;
    }
  }
}
</style>
