class FreightCalculate {

  #data = {
    Nprod: null,
    NperBox: null,
    Size: null,
    Weight: null,
    Volume: null,
    Line: null,
    From: null,
    To: null
  }

  /**
   *
   * @param {Calculator.BuyModel} buyModel
   * @return {FreightCalculate}
   */
  static from(buyModel) {
    let obj = new FreightCalculate();
    let data = obj.get();
    data.Nprod = obj.parseNum(buyModel.size)
    data.NperBox = obj.parseNum(buyModel.box)
    data.Size = buyModel.boxSize
    data.Weight = obj.parseNum(buyModel.price.weight)
    data.Volume = obj.parseNum(buyModel.price.volume)
    return obj;
  }

  setLine(line){
    this.#data.Line = line;
    return this;
  }
  setFrom(From){
    this.#data.From = From;
    return this;
  }

  setTo(To){
    this.#data.To = To;
    return this;
  }

  parseNum(num) {
    if (typeof num == "number") return num
    return Number(num)
  }

  get() {
    return this.#data
  }

}

export default FreightCalculate;