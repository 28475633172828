<template>
  <div :style="`height: ${height}px`"></div>
</template>

<script>
export default {
  name: "HeaderSpace",
  props: {
    height: {
      type: Number,
      default: 44
    }
  }
}

</script>
<style scoped lang="scss">
	
</style>